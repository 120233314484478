import { FC } from 'react';

import { styled } from '../../../theme';
import { IconButton } from '../../Button/IconButton';
import { Tooltip } from '../../Tooltip';

import { TFavouritesProps } from './Favourites.types';

const Root = styled('span')({
  display: 'flex',
});

export const Favourites: FC<TFavouritesProps> = ({ isActive, onClick }) => {
  return (
    <Tooltip
      title={isActive ? 'Remove from favourites' : 'Add to favourites'}
      placement="right"
      sx={{
        '&.MuiTooltip-tooltip': {
          padding: '8px',
        },
      }}
    >
      <Root>
        <IconButton
          iconName="StarIcon"
          iconSize={16}
          isActive={isActive}
          onClick={onClick}
        />
      </Root>
    </Tooltip>
  );
};
