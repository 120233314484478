import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { DefaultThemeProvider } from '../../theme';

import {
  Root,
  StyledBreadcrumbs,
  StyledLink,
  StyledSelect,
  StyledSeparator,
  StyledSpan,
} from './Breadcrumbs.styled';
import { Favourites } from './Favourites/Favourites';
import { BreadcrumbsProps } from './types';

const SeparatorIcon = () => <StyledSeparator size={12} applyStates={false} />;

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  bookmarks,
  hasFavourites = false,
  history,
  isFavourite = false,
  onChangeSelected,
  onClickFavourite,
  selectedBookmark,
  separator = <SeparatorIcon />,
  value,
}) => {
  const [selectedBookmarkState, setSelectedBookmarkState] = useState<
    string | undefined
  >(selectedBookmark);

  useEffect(() => {
    if (selectedBookmark) {
      setSelectedBookmarkState(selectedBookmark);
    }
  }, [selectedBookmark]);

  const bookmarkOptions =
    bookmarks &&
    bookmarks.map((el) => ({
      value: el.linkTo,
      label: el.text,
    }));

  const breadcrumbValue = useMemo(
    () => (bookmarks ? value.slice(0, -1) : value),
    [value, bookmarks],
  );

  const handleBookmarkSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (typeof onChangeSelected === 'function') {
      onChangeSelected(event.target.value);
    }
    setSelectedBookmarkState(event.target.value);
    if (history) {
      history.push(event.target.value);
    }
  };

  const handleClickFavourite = () => {
    if (onClickFavourite) {
      onClickFavourite();
    }
  };

  return (
    <DefaultThemeProvider>
      <Root>
        <StyledBreadcrumbs
          aria-label="breadcrumb"
          separator={separator}
          bookmarks={bookmarks}
        >
          {breadcrumbValue.map((menuItem) =>
            !menuItem.disabled ? (
              <StyledLink
                underline="none"
                color="inherit"
                key={menuItem.text}
                component={menuItem.routerLink}
              >
                {menuItem.text}
              </StyledLink>
            ) : (
              <StyledSpan key={menuItem.text}>{menuItem.text}</StyledSpan>
            ),
          )}
          {bookmarks &&
            (bookmarks.length > 1 && bookmarkOptions?.length ? (
              <StyledSelect
                size="small"
                options={bookmarkOptions}
                value={selectedBookmarkState || bookmarkOptions[0].value}
                onChange={handleBookmarkSelect}
                sx={{
                  width: 150,
                }}
              />
            ) : (
              <StyledSpan key={bookmarks[0].text}>
                {bookmarks[0].text}
              </StyledSpan>
            ))}
        </StyledBreadcrumbs>
        {hasFavourites && (
          <Favourites isActive={isFavourite} onClick={handleClickFavourite} />
        )}
      </Root>
    </DefaultThemeProvider>
  );
};
