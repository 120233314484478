import { createBreakpoints } from '@mui/system';

export const defaultThemeBreakpoints = createBreakpoints({
  values: {
    xs: 0,
    tablet: 512,
    sm: 768,
    md: 1024,
    lg: 1280,
    lr: 1440,
    xl: 1920,
  },
});

export const THEME_NAME = {
  DEFAULT: 'default',
  DARK: 'dark',
} as const;
